import { Injectable } from '@angular/core';
import { AuthStorageService } from './auth-storage-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserProfileDto } from '@as/api';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static readonly UserIdJwtKey: string = 'UserId';
  private static readonly OrganizationIdJwtKey: string = 'OrganizationId';

  private helper = new JwtHelperService();

  constructor(
    private readonly authStore: AuthStorageService,
    private readonly router: Router,
  ) {}

  public logout(): void {
    this.authStore.clearTokens();
  }

  public isLoggedIn(): boolean {
    return this.authStore.getJwt() != null;
  }

  public getUserId(): string {
    const userId = this.getTokenValue(AuthService.UserIdJwtKey);

    if (userId == null) {
      throw new Error('User is not logged or userId is not specified in auth token');
    }

    return userId;
  }

  public getOrganizationId(): string {
    const organizationId = this.getTokenValue(AuthService.OrganizationIdJwtKey);

    if (organizationId == null) {
      throw new Error('User is not logged or organizationId is not specified in auth token');
    }

    return organizationId;
  }

  public getUserFullName(): string | null | undefined {
    return this.getUserProfile()?.fullName;
  }

  public getUserProfile(): UserProfileDto | null | undefined {
    const userProfile = this.authStore.getUserProfile();
    if (userProfile == null) {
      this.logout();
      console.warn('Unable to get user profile.');
      return null;
    }

    const userProfileDto = JSON.parse(userProfile) as UserProfileDto;
    if (userProfileDto == null) {
      this.logout();
      console.warn('Unable to parse user.');
      return null;
    }

    return userProfileDto;
  }

  private getTokenValue(key: string): string | null {
    const token = this.authStore.getJwt();
    if (token == null) {
      return null;
    }
    const decodedToken = this.helper.decodeToken(token);
    return decodedToken[key];
  }
}
